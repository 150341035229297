import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../components/useDocumentTitle";

const NotFound = () => {
  useDocumentTitle("Planny HR - Plataforma de Recursos Humanos");
  return (
    <div className="main-page-wrapper p0">
      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/media/404.svg"
            alt="media"
            className="w-100 illustration"
          />
          <img
            src="images/media/404-q.svg"
            alt="media"
            className="shapes qus"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <img src="images/logo/logo.svg" style={{width: '150px'}} alt="brand" />
            </Link>
          </div>
          <h4 className="font-slab d-inline d-md-none">
            Lo sentimos, pero no<br />
            hemos encontrado <br />
            lo que estás buscando.
          </h4>

          <h3 className="font-slab d-none d-md-inline">
            Lo sentimos, pero no<br />
            hemos encontrado <br />
            lo que estás buscando.
          </h3>

          <p className="font-rubik">
            El enlace a esta página puede ser incorrecto, estar desactualizado o se ha movido a otro lugar.

          </p>

          <Link
            to="/"
            className="back-home btn-primary font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Volver a Home</span>
            <img src="images/icon/53.svg" alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
};

export default NotFound;
