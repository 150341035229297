import React from "react";

const FancyFeatureTewentyFour = () => {
  return (
    <div className="row">

      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FF4F86" }}
            >
              <img src="images/icon/126.svg" alt="icon" />
              {/* <img src="images/icon/125.svg" alt="icon" /> */}
            </div>
            <div className="text">
              <h4>Control de horario</h4>
              <p>
                Monitorea en tiempo real la asistencia de tus colaboradores, así como también supervisa que se encuentren en el área de trabajo.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFCF39" }}
            >
              <img src="images/icon/130.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Gestión de incidencias</h4>
              <p>
                Administra fácilmente las incidencias de tus colaboradores, autoriza solicitudes y configura flujos de acuerdo a tu empresa.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#6D64FF" }}
            >
              <img src="images/icon/120.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Portal de colaborador</h4>
              <p>
                Tus colaboradores pueden consultar en cualquier momento el estado de sus solicitudes, marcar entradas y salidas, actualizar su documentación independientemente.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#E752FF" }}
            >
              <img src="images/icon/128.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Comunicados internos</h4>
              <p>
                Un canal de comunicación exclusivo dentro de tu empresa.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      {/* <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#29EEFB" }}
            >
              <img src="images/icon/129.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Driving Lessons</h4>
              <p>Driving Schools, Driving Instructors.</p>
            </div>
          </div>
        </div>{" "}
      </div>

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#32B5FF" }}
            >
              <img src="images/icon/130.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Education System</h4>
              <p>
                Universities, Colleges, Schools, Libraries, Parent meetings,
                Tutoring lessons.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFA361" }}
            >
              <img src="images/icon/131.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Medical services</h4>
              <p>
                Massage & Body Treatments, Dental Clinics, Medical Clinics &
                more.
              </p>
            </div>
          </div>
        </div>{" "}
      </div> */}
    </div>
  );
};

export default FancyFeatureTewentyFour;
